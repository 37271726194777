<template>
  <el-row>
    <div style="text-align: left;">
      <p>現在是萬物互聯的時代,建立自己的雲平台已經是傳統的設備製造廠家的迫切需求。每個廠家的業務和需求都不大一樣,故此並不存在一個通用的產品可以簡單購買直接使用,客制化是不可避免的。</p>
    </div>
  </el-row>
  <el-row>
    <div style="text-align: left;">
      RoundGIS實驗室從2009年便開始為客人開發各種物聯網平台。 RGZero和GAS是其中的代表作,關於RGZero的介紹可以點擊
      <a href="#" v-on:click.prevent="()=>{this.$router.push('/maintw/fm')}">這裡</a>。GAS是溫室自動化管理方案,具體介紹可以點擊
      <a href="https://github.com/roundgis/gas/wiki" target="_blank">這裡</a>
    </div>
  </el-row>
</template>

<script>
export default {
  name: "IotDev_SD_TW",
  emits: ["goto-fm-component"],
  methods: {
    onGotoFM() {
      this.$emit("goto-fm-component");
    }
  }
}
</script>

<style scoped>

</style>