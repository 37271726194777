<template>
  <el-row>
    <div style="text-align: left;">
      <p>RoundGIS實驗室成員有超過10年的系統設計,軟體開發和整合的經驗。為跨國公司,政府部門,NGO和中小企業提供過軟體開發服務。目前我們提供如下開發服務</p>
    </div>
  </el-row>
  <el-row>
    <ul>
      <li><a href="#" v-on:click.prevent="()=>{this.$router.push('/maintw/iot-dev-sd');}">物聯網平台開發</a></li>
      <li><a href="#" v-on:click.prevent="()=>{this.$router.push('/maintw/dotnet-dev-sd');}">.NET系統開發</a></li>
      <li>AI平台開發</li>
    </ul>
  </el-row>
  <el-row>&nbsp;</el-row>
  <el-row>
    <div style="text-align: left;">RoundGIS實驗室致力於為客人提供現代和具有成本競爭力的開發方案，因此我們使用業界主流的開源技術和最佳實踐。以下是我們使用的部分技術</div>
  </el-row>
  <el-row>
    <ul>
      <li><div style="background-color: #DCDFE6; padding: 5px; margin-bottom: 5px; text-align: left;">Python, 最受歡迎的動態語言,是現代AI和機器學習系統的基石</div></li>
      <li><div style="background-color: #DCDFE6; padding: 5px; margin-bottom: 5px; text-align: left">Vue.js, 最流行的web框架之一</div></li>
      <li><div style="background-color: #DCDFE6; padding: 5px; margin-bottom: 5px; text-align: left">.Net, 來自微軟的企業級開發框架</div></li>
      <li><div style="background-color: #DCDFE6; padding: 5px; margin-bottom: 5px; text-align: left">Mongodb, 最流行的分散式NoSql資料庫</div></li>
      <li><div style="background-color: #DCDFE6; padding: 5px; margin-bottom: 5px; text-align: left">PostgreSQL, 功能最強大的關係型資料庫</div></li>
    </ul>
  </el-row>
  <el-row>&nbsp;</el-row>
  <el-row>
    <p>如有興趣,請點擊<a href="#" v-on:click.prevent="()=>{this.$router.push('/maintw/aboutus')}">這裡</a>聯繫我們</p>
  </el-row>
</template>

<script>
export default {
  name: "Overview_SD_TW",
  emits: ['goto-iot-dev-component'],
  methods: {
    onIOT() {
      this.$emit('goto-iot-dev-component');
    }
  }
}
</script>

<style scoped>

</style>