<template>
  <el-row>
    <p>
      RoundGIS Lab is a private lab at Taiwan. We have provided software development and consulting services in a variety of industries since 2009.
    </p>
  </el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>
    <h4>Contact Us:</h4>
  </el-row>
  <el-row>
    <label>LINE:</label><span style="padding-left: 5px;"><label>vinabasa</label></span>
  </el-row>
  <el-row>
    <label>Email:</label><span style="padding-left: 5px;"><label>ygl.roundgis@gmail.com</label></span>
  </el-row>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped>

</style>