<template>
  <el-row>
    <p>
      RoundGIS實驗室位於台灣。我們從2009年開始為各行各業提供軟體開發和諮詢服務
    </p>
  </el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <el-row>
    <h4>聯繫我們:</h4>
  </el-row>
  <el-row>
    <label>LINE:</label><span style="padding-left: 5px;"><label>vinabasa</label></span>
  </el-row>
  <el-row>
    <label>Email:</label><span style="padding-left: 5px;"><label>ygl.roundgis@gmail.com</label></span>
  </el-row>
</template>

<script>
export default {
  name: "AboutUsTw"
}
</script>

<style scoped>

</style>