<template>
<el-container>
  <el-main>
    <el-carousel :autoplay="false"  type="card">
      <el-carousel-item v-for="(item,idx) of image_urls" :key="idx">
        <img v-bind:src="item" style="display: block; height: 280px; " />
      </el-carousel-item>
    </el-carousel>
    <el-row style="margin-bottom: 20px;">
      <el-col :span="24">
        <el-card>
          <template #header>
            <div>
              <span>Introduction</span>
            </div>
          </template>
          <div>
            <p>We have spent quite some time on GPS Fleet Management system since 2009.</p>
            <p>RGZero, one of the most prominent fleet management system in south-east Asia was adopted by</p>
            <p>many users such as DKSH, ACLEDA Bank, Taxi Choice and KaiSer furniture</p>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row style="margin-bottom: 20px;">
      <el-col :span="24">
        <el-card>
          <template #header>
            <div>
              <span>Features</span>
            </div>
          </template>
          <div>
            <p>Cloud based</p>
            <p>Support numerous tracker protocols</p>
            <p>Easy to use</p>
            <p>Open sources</p>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <el-card>
          <template #header>
            <div>
              <span>Functions</span>
            </div>
          </template>
          <div>
            <p>Realtime Positioning</p>
            <p>Live Data such as Engine, ACC, Fuel level and others</p>
            <p>Geo Fencing</p>
            <p>Comprehensive reporting and charting</p>
          </div>
        </el-card>
      </el-col>
    </el-row>

  </el-main>
</el-container>
</template>

<script>
export default {
  name: "FleetManagement",
  setup() {
    return {
      image_urls: [
        require("../assets/images/image--205.jpg"),
        require("../assets/images/image--212.jpg"),
        require("../assets/images/image--219.jpg"),
        require("../assets/images/image--309.png"),
        require("../assets/images/image--233.jpg"),
      ]
    }
  }
}
</script>

<style scoped>

</style>