<template>
  <div>
    <el-row>&nbsp;</el-row>
    <el-row>&nbsp;</el-row>
    <el-row>&nbsp;</el-row>
    <el-row>
        Smart Planting solution (a.k.a rgtea) is for helping farmers understand their crops status better.

    </el-row>
    <el-row>&nbsp;</el-row>
    <el-row>&nbsp;</el-row>
    <el-row>&nbsp;</el-row>
    <el-row>
        How to use?
    </el-row>
    <el-row>&nbsp;</el-row>
    <el-row style="margin-bottom: 5px;">
      Take a photo to your crops
    </el-row>
    <el-row style="margin-bottom: 5px;">
      Send it to us via cloud service
    </el-row>
    <el-row>
      Our service will tell you what happened to your crops
    </el-row>
  </div>
</template>

<script>
export default {
  name: "SmartPlanting"
}
</script>

<style scoped>

</style>