<template>
  <el-menu mode="horizontal" :ellipsis="false">
    <el-menu-item index="11" v-on:click="()=>{this.$router.push('/maintw/aboutus');}">關於我們</el-menu-item>
    <el-sub-menu index="12">
      <template #title>解決方案</template>
      <el-menu-item v-on:click="()=>{this.$router.push('/maintw/fm');}">車隊管理</el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="13">
      <template #title>應用開發</template>
      <el-menu-item v-on:click="()=>{this.$router.push('/maintw/overview-sd');}">總覽</el-menu-item>
    </el-sub-menu>
  </el-menu>
  <router-view name="b"></router-view>
</template>

<script>
export default {
  name: "Main_TW",
  components: {

  },
  data() {
    return {
      show_tw_component: ''
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>