<template>
  <el-row>
    <el-col :span="6">
      <el-link v-on:click.prevent="()=>{this.$router.push('/maineng');}">English</el-link>
    </el-col>
    <el-col :span="6"><el-link v-on:click.prevent="()=>{this.$router.push('/maintw')}">中文</el-link></el-col>
  </el-row>
  <el-row>&nbsp;</el-row>
  <el-row>&nbsp;</el-row>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  data () {
    return {
    }
  },
  methods: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
