<template>
  <el-container>
    <el-main>
      <el-carousel :autoplay="false"  type="card">
        <el-carousel-item v-for="(item,idx) of image_urls" :key="idx">
          <img v-bind:src="item" style="display: block; height: 280px; " />
        </el-carousel-item>
      </el-carousel>
      <el-row style="margin-bottom: 20px;">
        <el-col :span="24">
          <el-card>
            <template #header>
              <div>
                <span>簡介</span>
              </div>
            </template>
            <div>
              <p>我们自从2009年便开始研发车队管理系统</p>
              <p>RGZero是我們自主研發的車隊管理系統, 曾在東南亞被廣泛應用。客人包括但不限於大昌華嘉,ACLEDA銀行,Choice計程車和凱勝家具</p>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-row style="margin-bottom: 20px;">
        <el-col :span="24">
          <el-card>
            <template #header>
              <div>
                <span>特色</span>
              </div>
            </template>
            <div>
              <p>使用雲端技術</p>
              <p>支援多種車機協議</p>
              <p>易於使用</p>
              <p>開源</p>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-card>
            <template #header>
              <div>
                <span>功能</span>
              </div>
            </template>
            <div>
              <p>實時定位</p>
              <p>實時資料例如引擎狀態,acc,燃油量等</p>
              <p>電子圍欄</p>
              <p>豐富的報表</p>
            </div>
          </el-card>
        </el-col>
      </el-row>

    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "FleetManagementTw",
  setup() {
    return {
      image_urls: [
        require("../assets/images/image--205.jpg"),
        require("../assets/images/image--212.jpg"),
        require("../assets/images/image--219.jpg"),
        require("../assets/images/image--309.png"),
        require("../assets/images/image--233.jpg"),
      ]
    }
  }
}
</script>

<style scoped>

</style>