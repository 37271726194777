<template>
  <el-row>
    <div style="text-align: left;">
      <p>RoundGIS實驗室目前提供如下.net開發程式服務</p>
    </div>

  </el-row>
  <el-row>&nbsp;</el-row>
  <el-row>
    <ul>
      <li><div class="list-div">Winforms, WPF桌面應用程式開發</div></li>
      <li><div class="list-div">.net core Web程式開發</div></li>
      <li><div class="list-div">後台程式開發</div></li>
      <li><div class="list-div">Xamarin手機應用開發</div></li>
    </ul>
  </el-row>
</template>

<script>
export default {
  name: "DotNet_SD_TW"
}
</script>

<style scoped>
  .list-div {
    background-color: #DCDFE6;
    padding: 5px;
    margin-bottom: 5px;
    text-align: left;
  }
</style>