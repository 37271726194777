<template>
  <el-menu mode="horizontal">
    <el-menu-item index="1" v-on:click="onAboutUs">About Us</el-menu-item>
    <el-sub-menu index="2">
      <template #title>Solutions</template>
      <el-menu-item @click="onFs">Fleet Management</el-menu-item>
      <el-menu-item @click="onSp">Smart Planting</el-menu-item>
    </el-sub-menu>
  </el-menu>
  <router-view name="a"></router-view>
</template>

<script>
export default {
  name: "Main_Eng",
  methods: {
    onAboutUs () {
      this.$router.push("/maineng/aboutus");
    },

    onFs() {
      this.$router.push("/maineng/fm");
    },

    onSp() {
      this.$router.push("/maineng/sp");
    },
  }
}
</script>

<style scoped>

</style>