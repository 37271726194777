import { createApp } from 'vue'
import * as VueRouter from 'vue-router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import AboutUs from "./components/AboutUs";
import FleetManagement from "./components/FleetManagement";
import SmartPlanting from "@/components/SmartPlanting";
import Main_Eng from "@/components/Main_Eng";
import Main_TW from "@/components/Main_TW";
import AboutUsTw from "@/components/AboutUsTw";
import FleetManagementTw from "@/components/FleetManagementTw";
import Overview_SD_TW from "@/components/Overview_SD_TW";
import IotDev_SD_TW from "@/components/IotDev_SD_TW";
import DotNet_SD_TW from "@/components/DotNet_SD_TW";

let routes = [
    {path: "/", redirect: "/maineng/aboutus"},
    {path: "/maineng", component: Main_Eng, children:[
            {path: "aboutus", components: {
                a: AboutUs
                }},
            {path: "fm", components: {
                a: FleetManagement
                }},
            {path: "sp", components: {
                a: SmartPlanting
                }}
        ]
    },
    {path: "/maintw", component: Main_TW, children: [
            {path: "aboutus", components: {
                b: AboutUsTw
                }},
            {path: "fm", components: {
                b: FleetManagementTw
                }},
            {path: "overview-sd", components: {
                b: Overview_SD_TW
                }},
            {path: "iot-dev-sd", components: {
                b: IotDev_SD_TW
                }},
            {path: "dotnet-dev-sd", components: {
                b: DotNet_SD_TW
                }}
        ]}
]

let route_obj = VueRouter.createRouter({routes, history: VueRouter.createWebHistory()})

createApp(App).use(route_obj).use(ElementPlus).mount('#app')
